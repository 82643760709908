import { Dialog, MenuItem, Select, TextField, Typography, useTheme, Autocomplete } from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import { FC, useEffect, useState } from "react";
import { IRecordsData, IUser } from "../../interfaces";
import { recordService, userService } from "../../service";
import { RECORD_PERMISSIONS, RECORD_PERMISSIONS_TYPES } from "../../utils/constants";
import CustomButton from "../common/custom-buttons/CustomButton";
import PermissionListCard from "../common/permission-list-card/PermissionListCard";
import Loader from "../../components/Loader";
import CircularProgress from '@mui/material/CircularProgress';

interface IProps {
    data: IRecordsData;
    open: boolean;
    onClose: VoidFunction;
}

const ShareRecord: FC<IProps> = ({ data, open, onClose }) => {
    const theme = useTheme();
    const { enqueueSnackbar: snackbar } = useSnackbar();
    const [sharingData, setSharingData] = useState<any[]>([]);
    const [email, setEmail] = useState<string>();
    const [permission, setPermission] = useState<string>('');
    const [usersData, setUsersData] = useState<IUser[]>([]);
    const [loading, setLoading] = useState(false);
    
    const fetchSharingData = () => {
        setLoading(true);
        if (!data?._id) {
            return;
        }

        recordService.fetchSharingData(data._id).then((res) => {
            setLoading(true);
            if (!res.status || !res.data) {
                return;
            }

            setSharingData(res.data);
            setLoading(false);
        });
    };

    const shareRecord = () => {
        const match = (sharingData || []).find(d => d.email === email);
        if (match) {
            snackbar('Email already exists', { variant: 'error' });
            return;
        }

        const payload = {
            id: data._id,
            email,
            permission
        };
        recordService.shareRecord(payload).then((res) => {
            if (!res.status) {
                snackbar(res.message || "Something went wrong", { variant: 'error' });
                return;
            }

            snackbar("Resource shared", { variant: 'success' });
            fetchSharingData();
        });
    };

    useEffect(() => {
        if (open && data?._id) {
            fetchSharingData();
            setPermission(RECORD_PERMISSIONS_TYPES.EDIT)
        }
    }, [open]);

    const fetchUsers = async () => {
        const resUsers = await userService.getAllUsers();
        if (!resUsers.status || !resUsers.data) {
            console.log(resUsers.message);
          return;
        }
        setUsersData(resUsers.data);
    };
    
    useEffect(() => {
        fetchUsers();
    }, [])

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="share-record"
            aria-describedby="share-record"
            maxWidth="sm"
            fullWidth
        >    
            <Box
                sx={{
                    padding: "24px"
                }}
            >
                <Typography sx={{
                    fontWeight: 400,
                    fontSize: "22px",
                    marginBottom:"18px",
                    color: theme.colors?.primary.default
                }}>
                    Share {data.name}
                </Typography>
                {loading ? (
                    <Loader isResponsive />
                    ) : (
                    <>
                <Box sx={{
                    display: "flex",
                    marginTop: "10px"
                }}>
                    <Autocomplete 
                      options={usersData}
                      getOptionLabel={(option) => option.email}
                      value={usersData.find((ud) => ud.email === email)}
                      onChange={(event: any, newValue: IUser | null) => {
                        newValue && setEmail(newValue.email);
                      }}
                      renderInput={(params) => <TextField {...params} label="Email" />}
                      fullWidth
                      noOptionsText={"Email not exist!"}
                    />
                    <Select
                        labelId="permission-select-label"
                        id="permission-select"
                        value={permission}
                        // label="Age"
                        onChange={(e) => {
                            setPermission(e.target.value);
                        }}
                        sx={{
                            width: "165px",
                            marginLeft: "10px",
                            textTransform: "capitalize"
                        }}
                    >
                        {RECORD_PERMISSIONS.map((permission, i) => (
                            <MenuItem value={permission} key={i} sx={{
                                textTransform: "capitalize"
                            }}>
                                {permission}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
                <Box>
                    <Typography sx={{
                        fontSize: "16px",
                        color: theme.colors?.darkGreyText.default,
                        margin: "20px 0"
                    }}>
                        People with access
                    </Typography>
       
                    <Box pb={1.5} sx={{
                        maxHeight: "200px",
                        overflowY: "auto"
                    }}>
                        {sharingData.map((data, i) => (
                            <PermissionListCard data={data} refresh={fetchSharingData} key={i} />
                        ))}
                    </Box>
                   
                </Box> </>
                    )}
            <Box sx={{
                textAlign: "right",
                marginTop: "10px"
            }}>
                <CustomButton onClick={shareRecord} disabled={!email || !permission}>
                    Submit
                </CustomButton>
            </Box>
            </Box>
            </Dialog>
    );
};

export default ShareRecord;