import { Box, Grid, Typography, useTheme } from "@mui/material";
import SubTopBar from "../../components/template/SubTopBar";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { recordService } from "../../service";
import { IRecordsData } from "../../interfaces";
import FolderCardComponent from "../../components/card/FolderCardComponent";
import FolderUserIcon from "../../components/common/icons/folder-user-icon/FolderUserIcon";
import { BiFile, BiImage, BiPlayCircle } from "react-icons/bi";
import PDFIcon from "../../components/common/icons/pdf-icon/PDFIcon";
import { apiConfig } from "../../config/api-config";
import Loader from "../../components/Loader";

const Search = () => {
  const { key } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const [records, setRecords] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const query = async () => {
    setLoading(true);
    const res = await recordService.searchRecords(key || "");
    setLoading(false);
    if (!res || !res.status || !res.data) {
      return;
    }

    const data = [...res.data.folders, ...res.data.files];
    const locationPromises = data.map(async (element) => {
      const location = await fetchLocation(element._id);
      element.location = `${location}${element.name}`;
      console.log("Set Path\t\t\t", element.location);
    });
    await Promise.all(locationPromises);
    setRecords(data || []);
    console.log("DATA\t\t",data);
  };

  const fetchLocation = async (data: string) => {
    return recordService.fetchParents(data).then((res) => {
      console.log("fetchLocation\t\t", res);
      let path = "Files/";
      const parents = res.data?.reverse();
      parents?.map((parent) => {
        path = path + parent.name + "/";
      });
      console.log("fetchLocationPath\t\t", path);
      return path;
    });
  };

  const getRecordType = (record) => {
    if (!record.name) {
      return "file";
    }

    const lastindexOfDot = record.name.lastIndexOf(".");
    if (!lastindexOfDot) {
      return "file";
    }

    const extension = record.name.substring(lastindexOfDot + 1);
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "webp", "svg"];
    const videoExtensions = [
      "mp4",
      "mov",
      "wmv",
      "flv",
      "avi",
      "avchd",
      "webm",
      "mkv",
      "3gp",
    ];
    if (imageExtensions.includes(extension.toLowerCase())) {
      return "image";
    }

    if (extension.toLowerCase() === "pdf") {
      return "pdf";
    }

    if (videoExtensions.includes(extension.toLowerCase())) {
      return "video";
    }

    return "file";
  };

  useEffect(() => {
    console.log("hook");
    if (!key) {
      return;
    }

    query();
  }, [key]);

  return (
    <>
      <SubTopBar title="Search Results" />
      <Box
        display="flex"
        flexDirection="column"
        paddingRight={4}
        paddingBottom={4}
        gap={1}
        sx={{
          height: "calc(100% - 125px)",
          overflowY: "auto",
        }}
      >
        {loading ? (
          <Loader isResponsive />
        ) : (
          <>
            {records.length ? (
              <Grid container spacing={1.5}>
                {records.map((record, i) => (
                  <Grid item md={3} key={i}>
                    <FolderCardComponent
                      id={record._id}
                      record={record}
                      title={record.name}
                      allowRename={true}
                      allowLocation={true}
                      dIcon={
                        record.isFolder ? (
                          // <BiFolder color="red" fontSize={36} />
                          <FolderUserIcon
                            sx={{
                              fontSize: "36px",
                              color: theme.colors?.colorSeven.default,
                            }}
                          />
                        ) : getRecordType(record) === "image" ? (
                          <BiImage fontSize={36} />
                        ) : getRecordType(record) === "video" ? (
                          <BiPlayCircle fontSize={36} />
                        ) : getRecordType(record) === "pdf" ? (
                          <PDFIcon />
                        ) : (
                          <BiFile fontSize={36} />
                        )
                      }
                      clickHandler={() => {
                        if (record.isFolder) {
                          navigate(`/files/${record._id}`);
                          return;
                        }

                        window.open(
                          // `${record.img}`,
                          `${apiConfig.s3Url}${record.img}`,
                          "_blank"
                        );
                        // setViewerDetails(`${apiConfig.s3Url}${record.name}`);
                        // setViewerDetails(record.img);
                      }}
                      dense
                    />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Typography>No results</Typography>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default Search;
