import * as React from "react";
import { styled, alpha, useTheme } from "@mui/material/styles";

import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "../../components/common/custom-buttons/IconButton";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { BiBell } from "react-icons/bi";
import { clearSessionStorage, clearStorage } from "../../utils/storage-utils";
import { useNavigate, useParams } from "react-router-dom";
import ProfileDialogue from "../dialogue/ProfileDialogue";
import ProfileAvatar from "../common/profile-avatar/ProfileAvatar";

export const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 10,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginRight: theme.spacing(1.4),
    width: "491px",
  },
  height: 56,
}));

export const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 0),
  height: "43px",
  lineHeight: "43px",
  position: "absolute",
  //pointerEvents: "pointer",
  right: "-18px",
  top: "6px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "#8A8A8A",
  height: 56,
  "& .MuiInputBase-input": {
    padding: theme.spacing(0, 0, 0, 0),
    // vertical padding + font size from searchIcon
    paddingRight: `calc(1em + ${theme.spacing(4)})`,
    paddingLeft: "18px",
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 443,
    },
    background: "#F3F4F8",
    borderRadius: 8,
  },
}));

const TopBar = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { key } = useParams();
  const [profileOpen, setProfileOpen] = React.useState(false);
  const [searchKey, setSearchKey] = React.useState(key || "");
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setProfileOpen(true);
  };

  const handleClose = () => {
    setProfileOpen(false);
  };

  const handleLogout = () => {
    clearSessionStorage();
    clearStorage();
    navigate("/sign-in");
    // handleClick();
  };

  React.useEffect(() => {
    if (!key && searchKey) {
      setSearchKey("");
    }
  }, [key]);

  return (
    <>
      <Toolbar sx={{ height: 73 }}>
        <Search sx={{ pl: 0 }}>
          <StyledInputBase
            placeholder="Search…"
            inputProps={{ "aria-label": "search" }}
            sx={{ "& .MuiInputBase-input": { height: "43px" } }}
            value={searchKey}
            onChange={(e) => {
              setSearchKey(e.target.value);
            }}
          />
          <SearchIconWrapper>
            <IconButton
              sx={{ height: 43, minWidth: 43 }}
              onClick={() => {
                if (!key && searchKey) {
                  navigate(`/search/${searchKey}`);
                  return;
                }

                if (key) {
                  setSearchKey("");
                  navigate("/files");
                }
              }}
            >
              {key ? (
                // <BiX />
                <CloseIcon sx={{ color: theme.colors?.primary.default }} />
              ) : (
                <SearchIcon sx={{ color: "#2C2B83" }} />
              )}
            </IconButton>
          </SearchIconWrapper>
        </Search>
        <Box sx={{ flexGrow: 1 }} />
        <Box display="flex" gap={1.9}>
          <IconButton
            sx={{ height: 45, minWidth: 45 }}
            aria-label="account of current user"
            aria-haspopup="true"
            color="info"
            onClick={() => {
              navigate("/notification");
            }}
          >
            <BiBell fontSize={24} />
          </IconButton>

          <IconButton
            sx={{ height: 45, minWidth: 45 }}
            aria-label="account of current user"
            aria-haspopup="true"
            color="info"
            onClick={handleClick}
          >
            <ProfileAvatar size="45px" />
          </IconButton>
        </Box>
      </Toolbar>
      <ProfileDialogue
        open={profileOpen}
        onClose={() => {
          handleClose();
        }}
      />
    </>
  );
};

export default TopBar;
