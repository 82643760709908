import { Box, Dialog, Typography, useTheme } from "@mui/material";
import { FC } from "react";
import LinearProgressWithLabel from "../common/LinearProgressWithLabel/LinearProgressWithLabel";
import LinearProgress from '@mui/material/LinearProgress';
import { useEffect, useState } from "react";
import Fade from "@mui/material/Fade";


interface Iprops {
    open: boolean;
    onClose: VoidFunction;
    progress: number;
}

const FileUploadProgress: FC<Iprops> = ({ open, onClose, progress }) => {
    const theme = useTheme();
    const [showFinalizingMessage, setShowFinalizingMessage] = useState(false);

    useEffect(() => {
        if (progress === 100) {

            setTimeout(() => {
                setShowFinalizingMessage(true);
            }, 10000); // Delay in milliseconds (3 seconds)
        }
    }, [progress]);

    useEffect(() => {
        if (!open) {
          setShowFinalizingMessage(false);
        }
      }, [open]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            // sx={{
            //     ".MuiModal-backdrop": {
            //     background: "transparent",
            //     },
            // }}
            maxWidth="sm"
            fullWidth
            PaperProps={{
                sx: {
                    width: "100%",
                    maxWidth: "550px!important",
                    padding: "24px"
                },
            }}
        >
            {(progress < 100 || !showFinalizingMessage) && (
                <Box sx={{ width: 450, textAlign: "center" }}>
                    <Typography variant="h5" sx={{
                        color: theme.colors?.primary.default
                    }}>
                        Upload Status
                    </Typography>
                    <LinearProgressWithLabel value={progress} />
                </Box>
            )}
            {(progress == 100) && showFinalizingMessage && (
                <Fade in={true} timeout={300}>
                    <Box sx={{ width: 450, textAlign: "center" }}>
                        <Typography variant="h5" sx={{
                            color: theme.colors?.primary.default,
                            marginBottom: '1rem'
                        }}>
                            Finalizing upload. It might take a few minutes.
                        </Typography>
                        <LinearProgress />
                    </Box>
                </Fade>
            )}
        </Dialog>
    );
};

export default FileUploadProgress;