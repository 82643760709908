import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { IconType } from "react-icons/lib";
import { Menu, MenuItem, SxProps, Tooltip, useTheme } from "@mui/material";
import { CardType } from "../../enum";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { recordService } from "../../service";
import { useSnackbar } from "notistack";
import ShareRecord from "../share-record/ShareRecord";
import { IRecordsData } from "../../interfaces";
import axios from "axios";
import auth from "../../service/auth-service";
import { clearStorage, getAuthStorage } from "../../utils/storage-utils";
import { useState } from "react";
import FileLoader from "../FileLoader";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { apiConfig } from "../../config/api-config";

interface IProps {
  id?: string;
  record?: IRecordsData;
  title: string;
  dIcon: React.ReactNode;
  cardColor?: string;
  sx?: SxProps;
  type?: CardType;
  dense?: boolean;
  clickHandler?: VoidFunction;
  clickSelection?: VoidFunction;
  fetchRecords?: VoidFunction;
  isDelete?: boolean;
  isEdit?: boolean;
  isSelected?: boolean;
  onDelete?: (id: string) => void;
  isRestore?: boolean;
  onRestore?: (id: string) => void;
  setInitialLoadingComplete?: (val: boolean) => void;
  allowShare?: boolean;
  allowRename?: boolean;
  handleRenameTrigger?: () => void;
  allowLocation?: boolean;
}

const FolderCardComponent: React.FC<IProps> = ({
  id,
  record,
  title,
  dIcon,
  cardColor,
  sx,
  type,
  dense,
  clickHandler,
  clickSelection,
  fetchRecords,
  isDelete = true,
  isEdit = true,
  isSelected,
  onDelete,
  isRestore,
  onRestore,
  setInitialLoadingComplete,
  allowShare,
  allowRename,
  handleRenameTrigger,
  allowLocation,
}) => {
  const theme = useTheme();
  const { enqueueSnackbar: snackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openSharingBox, setSharingBox] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [fileLoading, setfileLoading] = useState(false);
  const [rename, setRename] = useState(false);
  const [newName, setNewName] = useState(title);
  const [trigger, setTrigger] = useState(false);

  React.useEffect(() => {
    if (!anchorEl) {
      setOpen(false);
      return;
    }

    setOpen(true);
  }, [anchorEl]);

  React.useEffect(() => {}, [trigger]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleDelete = (id: string) => {
    setInitialLoadingComplete && setInitialLoadingComplete(false);
    recordService.deleteRecord(id).then((res) => {
      setInitialLoadingComplete && setInitialLoadingComplete(true);
      if (!res.status) {
        snackbar("Delete failed", { variant: "error" });
        return;
      }

      snackbar("Delete success", { variant: "success" });
      handleClose();
      fetchRecords && fetchRecords();
    });
  };

  const handleShare = (data: {}) => {
    console.log("sharing:::::::::::::: ", id);
    setSharingBox(true);
  };

  const handleRename = () => {
    setRename(!rename);
  };

  const saveRename = async () => {
    setfileLoading(true);
    let extension = "";
    const lastIndex = title.lastIndexOf(".");
    if (lastIndex !== -1) {
      extension = title.substring(lastIndex + 1);
      console.log("Extension\t\t", extension); // This will log 'txt'
    }
    // console.log("Extension\t\t", extension, data);
    if (newName.length === 0) {
      // snackbar("Not", { variant: "error" });
      setfileLoading(false);
      handleRename();
      return;
    }
    const validateName = newName.replace(/[^a-zA-Z0-9-_]/g, "");
    // console.log("VALIDATED\t\t\t\t",validateName);
    if (id) {
      await recordService
        .updateRecord(id, `${validateName}.${extension}`)
        .then((res) => {
          if (!res.status) {
            setfileLoading(false);
            snackbar("Error renaming record", { variant: "error" });
            return;
          }
          snackbar("Succefully renamed record", { variant: "success" });
          if (handleRenameTrigger) {
            handleRenameTrigger();
          }
          setfileLoading(false);
        });
    }
    handleRename();
    setTrigger(!trigger);
  };

  if (fileLoading) {
    return <FileLoader />;
  }

  const handleEdit = async () => {
    const file = record?.img;
    const fileName = file?.substring(file.lastIndexOf("/") + 1);
    const fileType = fileName
      ?.substring(fileName.lastIndexOf(".") + 1)
      .toLowerCase();

    if (!file) {
      console.log("File Error");
      return;
    }

    console.log("type", fileType);
    console.log("fileName", fileName);

    try {
      let url;

      if (fileType === "docx" || fileType === "doc") {
        const response = await axios.post(
          apiConfig.serverURL + "/api/app/files/edit/doc",
          { img: fileName }
        );
        url = response.data.documentUrl;
      } else if (fileType === "pptx" || fileType === "ppt") {
        const response = await axios.post(
          apiConfig.serverURL + "/api/app/files/edit/slide",
          { img: fileName }
        );
        url = response.data.documentUrl;
      } else if (fileType === "xls" || fileType === "xlsx") {
        const response = await axios.post(
          apiConfig.serverURL + "/api/app/files/edit/sheet",
          { img: fileName }
        );
        url = response.data.documentUrl;
      } else {
        snackbar("Unsupported file type", { variant: "error" });
        console.log("Unsupported file type:", fileType);
        throw new Error("Unsupported file type");
      }

      console.log("documentUrl", url);
      return url;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };

  const card = (
    <>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: dense ? "row" : "column",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          minHeight: dense ? 85 : "100%",
          padding: dense ? 2 : "0px",
          paddingBottom: dense ? `16px !important` : "8px !important",
          cursor: "pointer",
          position: "relative",
          "&:hover": {
            backgroundColor: "#E4ECFB",
          },
          backgroundColor: isSelected ? "#E4ECFB" : "none",
        }}
        onClickCapture={clickSelection}
        onDoubleClickCapture={clickHandler}
      >
        <Box
          display={"flex"}
          flexDirection="column"
          width={dense ? "fit-content" : "100%"}
          height={dense ? "unset" : "140px"}
          alignItems="center"
          justifyContent={type === CardType.IMG ? "unset" : "center"}
        >
          {dIcon}
        </Box>
        {!rename && (
          <Tooltip title={title}>
            <Typography
              sx={{
                fontSize: "15px",
                paddingX: "14px",
                paddingBottom: dense ? "0px" : "5px",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                width: "100%",
                textAlign: dense ? "start" : "center",
              }}
              color="text.primary"
              // gutterBottom
            >
              {title}
            </Typography>
          </Tooltip>
        )}
        {rename && (
          <>
            <Tooltip title={title}>
              <TextField
                id="outlined-multiline-flexible"
                label={title}
                multiline
                maxRows={5}
                fullWidth
                size="small"
                InputProps={{
                  sx: {
                    border: "#EB568F",
                  },
                }}
                variant="outlined"
                sx={{
                  marginLeft: "1rem",
                }}
                value={newName} // Bind the value to the state variable
                onChange={(e) => setNewName(e.target.value)} // Update state on change
              />
            </Tooltip>
            <Button
              variant="contained"
              sx={{
                "border-radius": "0 5px 5px 0",
                height: "2.6rem",
                transform: "translateX(-3px)",
                backgroundColor: "#EB568F",
                "&:hover": {
                  backgroundColor: "#2C2B83",
                },
              }}
              onClick={() => saveRename()}
            >
              Save
            </Button>
          </>
        )}
        <Box
          sx={
            !dense
              ? {
                  position: "absolute",
                  right: 10,
                  top: 10,
                  ...(!isDelete || !isRestore ? { display: "none" } : {}),
                }
              : {}
          }
        >
          {(isDelete || allowShare || isRestore || isEdit) && (
            <Button
              onClick={handleClick}
              sx={{
                padding: "6px",
                minWidth: "unset",
                color: theme.colors?.ternary.default,
                fontSize: "20px",
              }}
            >
              <BiDotsHorizontalRounded />
            </Button>
          )}
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {allowRename && (
              <MenuItem
                onClick={() => {
                  if (!id) {
                    return;
                  }

                  handleRename();
                }}
              >
                Rename
              </MenuItem>
            )}
            {allowShare && (
              <MenuItem
                onClick={() => {
                  if (!id) {
                    return;
                  }

                  handleShare(id);
                  handleClose();
                }}
              >
                Share
              </MenuItem>
            )}
            {isEdit && (
              <MenuItem
                onClick={async () => {
                  if (!id) {
                    return;
                  }
                  try {
                    setfileLoading(true);
                    const documentUrl = await handleEdit();
                    if (documentUrl) {
                      window.open(documentUrl, "_blank");
                      setfileLoading(false);
                    } else {
                      console.log("Failed to retrieve document URL");
                      setfileLoading(false);
                    }
                  } catch (error) {
                    console.error("Error:", error);
                    setfileLoading(false);
                  }
                }}
              >
                Edit
              </MenuItem>
            )}
            {isRestore && (
              <MenuItem
                onClick={() => {
                  if (!id) {
                    return;
                  }

                  handleClose();
                  onRestore && onRestore(id);
                }}
              >
                Restore
              </MenuItem>
            )}
            {isDelete && (
              <MenuItem
                onClick={() => {
                  if (!id) {
                    return;
                  }

                  onDelete ? onDelete(id) : handleDelete(id);
                  handleClose();
                }}
              >
                Delete
              </MenuItem>
            )}
            {allowLocation && (
              <MenuItem onClick={() => {}}>{record?.location}</MenuItem>
            )}
          </Menu>
        </Box>
        {record && (
          <ShareRecord
            open={openSharingBox}
            onClose={() => {
              setSharingBox(false);
              handleClose();
            }}
            data={record}
          />
        )}
      </CardContent>
    </>
  );

  return (
    <Card
      variant="outlined"
      sx={{
        width: "100%",
        padding: "0px",
        background: cardColor || "white",
        minHeight: dense ? 64 : "100%",
        borderRadius: "10px",
        ...{ sx },
      }}
    >
      {card}
    </Card>
  );
};

export default FolderCardComponent;
