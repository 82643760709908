import {
  BiHomeAlt,
  BiFolder,
  BiShareAlt,
  BiUser,
  BiHistory,
  BiTrash,
} from "react-icons/bi";
import { Location } from "react-router-dom";
import { ISideBarOption } from "../interfaces";
import { validateToken } from "./auth-utils";

export const sideBarOptions: ISideBarOption[] = [
  { title: "Dashboard", icon: BiHomeAlt, url: "/dashboard", name: "dashboard" },
  { title: "Files", icon: BiFolder, url: "/files", name: "files" },
  { title: "Shared", icon: BiShareAlt, url: "/shared", name: "shared" },
  { title: "My profile", icon: BiUser, url: "/profile", name: "profile" },
  { title: "Recent", icon: BiHistory, url: "/recent", name: "recent" },
  { title: "Users", icon: BiUser, url: "/users", name: "users" },
  { title: "Admins", icon: BiUser, url: "/admins", name: "admins" },
  {
    title: "Super admins",
    icon: BiUser,
    url: "/super-admins",
    name: "superAdmins",
  },
  {
    title: "Activities",
    icon: BiHistory,
    url: "/activities",
    name: "activities",
  },
  { title: "Trash", icon: BiTrash, url: "/trash", name: "trash" },
];

export const superAdminMenuItems = [
  "dashboard",
  "files",
  "users",
  "admins",
  "superAdmins",
  "activities",
  "trash",
];
export const adminMenuItems = [
  "dashboard",
  "files",
  "shared",
  "users",
  "activities",
  "trash",
];
export const userMenuItems = ["files", "shared", "trash"];
// export const userMenuItems = ['files', 'shared', 'profile', 'recent', 'trash'];

export const getTitle = (location: Location) =>
  sideBarOptions.find((d) =>
    location.pathname.toLowerCase().includes(d.url.toLowerCase())
  )?.title || "dashboard";

export const ROLES = {
  SUPER_ADMIN: "super_admin",
  ADMIN: "admin",
  USER: "user",
};
export const ROLE_TYPES = {
  super_admin: "Super Admin",
  admin: "Admin",
  user: "User",
};

export const SUPER_ADMIN_ROLE = "super_admin";
export const ADMIN_ROLE = "admin";
export const USER_ROLE = "user";

export const RECORD_PERMISSIONS_TYPES = {
  VIEW: "view",
  EDIT: "edit",
  OWNER: "owner",
};
export const RECORD_PERMISSIONS = [
  RECORD_PERMISSIONS_TYPES.VIEW,
  RECORD_PERMISSIONS_TYPES.EDIT,
];
export const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'svg'];